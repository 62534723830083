import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 90%;
  min-height: 100vh;

  @media (max-width: 767px) {
    width: 100%;
  }

  img {
    width: 80%;
  }
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-top: 30px;
    margin-top: 50px;
  }
  h1 {
    font-size: 50px;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .gen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gen input {
    height: 35px;
    width: 250px;
    font-size: 20px;
    padding-left: 5px;
    background-color: #dfdfdf;
    color: #000;
  }
  .button, a {
    position: relative;
    height: 38px;
    text-decoration: none;
    width: 100px;
    font-size: 18px;
    border: none;
    color: whitesmoke;
    background-color: var(--thirdColor);
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }
  button:active {
    box-shadow: none;
  }
  .extra {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  .output-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  //::after
  input[type="range"] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;

    /*required for proper track sizing in FF*/
    width: 300px;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
    margin-top: -4px;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  input[type="range"]::-moz-range-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
  }

  /*hide the outline behind the border*/
  input[type="range"]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type="range"]::-ms-track {
    width: 300px;
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }
  input[type="range"]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  input[type="range"]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #888;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ccc;
  }

  //::after
`

function Gen() {
  const [temp, setTemp] = useState("")
  const [word, setWord] = useState("")
  const [size, setSize] = useState(400)
  const [bgColor, setBgColor] = useState("ffffff")
  const [qrCode, setQrCode] = useState("")

  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode(
      `http://api.qrserver.com/v1/create-qr-code/?data=${word}&size=${size}x${size}`
    )
  }, [word, size, bgColor])

  // Updating the input word when user
  // click on the generate button
  function handleClick() {
    setWord(temp)
  }

  return (
    <Container>
      <div className="App">
        <h1>QR Code Generator</h1>
        <div className="input-box">
          <div className="gen">
            <input
              type="text"
              onChange={(e) => {
                setTemp(e.target.value)
              }}
              placeholder="Enter text to encode"
            />
            <button className="button" onClick={handleClick}>
              Generate
            </button>
          </div>
          <div className="extra">
            <h5>Dimension:</h5>
            <input
              type="range"
              min="200"
              max="600"
              value={size}
              onChange={(e) => {
                setSize(e.target.value)
              }}
            />
          </div>
        </div>
        <div className="output-box">
          <img src={qrCode} alt="" />
          <a href={qrCode} download="QRCode">
            Download
          </a>
        </div>
      </div>
    </Container>
  )
}

export default Gen
